@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=BIZ+UDPGothic:wght@400;700&display=swap');

/* 文字サイズのレスポンシブ化 */
@layer base {
  :root {
    font-size: 75%;
    /* = 12px */
  }

  @screen ssm {
    :root {
      font-size: 100%;
      /* = 16px */
    }
  }
}
@media (min-width: 1920) {
  .responsive-img {
    width: 30%;
    height: auto;
  }
}

@media (min-width: 1025px) {
  .responsive-img {
    width: 50%;
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .responsive-img {
    width: 70%;
    height: auto;
  }
}

@media (max-width: 767px) {
  .responsive-img {
    width: 100%;
    height: auto;
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'BIZ UDPGothic', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* overflow: hidden !important; */
  color: #3e2723;
}

.text-primary-light {
  color: #8d6e63;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* cognito signup 日本語化時に元の表示を強制的に非表示 */
[data-amplify-authenticator-confirmsignup] .amplify-text {
  display: none;
}
